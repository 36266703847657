import React from "react";
import axios from "../../utils/axios";
import styles from "../../styles/grooming.module.scss";
import cookie from "react-cookies";
import plushPuppy from "../../assets/images/plushPuppys.jpg";
import grooming_logo from "../../assets/images/grooming_logo.jpg";
import grooming_main from "../../assets/images/grooming_main.jpg";
import { Language } from "../../utils/lang";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

export default class Grooming extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            data: [],
            pricing: [],
        };
    }
    componentDidMount() {
        axios
            .get(`/grooming`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    data: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });

        axios
            .get("/pricing?type=grooming")
            .then((res) => {
                this.setState({
                    pricing: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, data, pricing } = this.state;
        const lang = cookie.load("language");

        const GetPricing = () =>
            pricing.length > 0
                ? pricing.map((price) => (
                      <tr
                          className={styles.grooming_price_element}
                          key={price.id}
                      >
                          <td>{price.name}</td>
                          <td>{price.price}</td>
                      </tr>
                  ))
                : null;

        if (error) {
            return (
                <div className="container flex justify-center items-center">
                    {" "}
                    <span>Error: {error}</span>{" "}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className="container flex justify-center items-center">
                    <div className="loading">Loading...</div>
                </div>
            );
        } else {
            return data.length > 0
                ? data.map((grooming) => (
                      <div className={styles.grooming_container}>
                          <div className={styles.grooming_container_title}>
                              {Language.grooming}
                          </div>

                          <div className={styles.grooming_wrapper}>
                              <div className={styles.grooming_left}>
                                  <div className={styles.grooming_left_desc}>
                                      <div
                                          dangerouslySetInnerHTML={{
                                              __html:
                                                  lang === "pl"
                                                      ? grooming.description_pl
                                                      : grooming.description_en,
                                          }}
                                      ></div>
                                  </div>

                                  <div className={styles.grooming_left_logo}>
                                      <div>
                                          <img
                                              src={grooming_logo}
                                              alt="Salon"
                                          />
                                      </div>
                                      <div>
                                          <img src={plushPuppy} alt="" />
                                      </div>
                                  </div>
                              </div>
                              <div className={styles.grooming_right}>
                                  <div className={styles.grooming_right_image}>
                                      <img src={grooming_main} alt="" />
                                  </div>

                                  <div
                                      className={styles.grooming_right_pricing}
                                  >
                                      <div className={styles.hotel_price_title}>
                                          {Language.pricing}
                                      </div>

                                      <table
                                          className={styles.hotel_price_table}
                                      >
                                          <thead>
                                              <tr>
                                                  <td>
                                                      {Language.pricing_name}
                                                  </td>
                                                  <td>
                                                      {Language.pricing_price}
                                                  </td>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <GetPricing />
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>

                          {grooming.images.length > 0 ? (
                              <div className={styles.grooming_gallery}>
                                  <div
                                      className={styles.grooming_gallery_title}
                                  >
                                      {Language.gallery}
                                  </div>

                                  <div
                                      className={styles.grooming_gallery_images}
                                  >
                                      <LightgalleryProvider
                                          lightgallerySettings={{
                                              download: false,
                                              enableDrag: false,
                                          }}
                                      >
                                          {grooming.images.map((im) => (
                                              <LightgalleryItem src={im.path}>
                                                  <img src={im.path} alt="" />
                                              </LightgalleryItem>
                                          ))}
                                      </LightgalleryProvider>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                  ))
                : null;
        }
    }
}

import axios from '../../utils/axios';
import styles from '../../styles/litters.module.scss';
import samoyed_template from '../../assets/images/samoyed-template.png';
import { Link } from 'react-router-dom';
import React from 'react';
import { Language } from '../../utils/lang';

export default class DogsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            litters: [],
            breedVariable: window.location.pathname.slice(9),
        };
    }
    componentDidMount() {
        axios
            .get(
                `/litters?breed=${this.state.breedVariable}&_sort=litterBirth&_order=DESC`
            )
            .then((res) => {
                this.setState({
                    isLoading: true,
                    litters: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, litters, breedVariable } = this.state;

        let breed;

        if (breedVariable === 'samoyed') breed = Language.samoyed;
        else if (breedVariable === 'chinese-crested') breed = Language.chinese;
        else breed = Language.miniature_american;

        const RenderLitters = () =>
            litters.length > 0 ? (
                litters.map((litter) => (
                    <Link
                        to={`/litters/${breedVariable}/${litter.id}`}
                        key={litter.id}
                    >
                        <div className={styles.litters_list_element}>
                            <div className={styles.litter_list_image}>
                                <img
                                    src={
                                        litter.images.filter(
                                            (i) => i.field === 'mainImg'
                                        ).length > 0
                                            ? litter.images.filter(
                                                  (i) => i.field === 'mainImg'
                                              )[0].path
                                            : samoyed_template
                                    }
                                    alt=''
                                    className={styles.litter_list_image_img}
                                />
                            </div>
                            <div className={styles.litter_list_name}>
                                {litter.litterName} <br />
                                <span>
                                    {new Date(
                                        litter.litterBirth
                                    ).toLocaleDateString()}
                                </span>
                            </div>
                        </div>
                    </Link>
                ))
            ) : (
                <div className='error_empty'>{Language.litter_empty}</div>
            );

        if (error) {
            return (
                <div className='container flex justify-center items-center'>
                    {' '}
                    <span>Error: {error}</span>{' '}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className='container flex justify-center items-center'>
                    <div className='loading'>Loading...</div>
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.litters_list_container}>
                        <div className={styles.litters_list_container_name}>
                            {breed}
                        </div>
                        <div className={styles.litters_list_container_list}>
                            <RenderLitters />
                        </div>
                    </div>
                </>
            );
        }
    }
}

import { Link } from 'react-router-dom';
import axios from '../../utils/axios';
import styles from '../../styles/dogs.module.scss';
import React from 'react';
import { Language } from '../../utils/lang';

export default class DogsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
            breedVariable: window.location.pathname.slice(6),
        };
    }
    componentDidMount() {
        axios
            .get(
                `/dogs?breed=${this.state.breedVariable}&_sort=dogBirth&_order=DESC`
            )
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, items, breedVariable } = this.state;

        let breed;

        if (breedVariable === 'samoyed') breed = Language.samoyed;
        else if (breedVariable === 'chinese-crested') breed = Language.chinese;
        else breed = Language.miniature_american;

        const GetDogsList = () =>
            items.length > 0 ? (
                items
                    .filter((f) => f.inMemory !== 1)
                    .map((dog) => (
                        <div className={styles.dogs_list_wrapper} key={dog.id}>
                            <img
                                src={
                                    dog.images.filter(
                                        (i) => i.field === 'mainImg'
                                    )[0].path
                                }
                                alt=''
                            />

                            <div
                                className={`
                                      ${
                                          dog.gender === 'male'
                                              ? styles.male
                                              : styles.female
                                      } ${styles.dogName}
                                  `}
                            >
                                <Link to={`/dogs/${breedVariable}/${dog.id}`}>
                                    <span>{dog.dogName}</span>
                                </Link>
                            </div>
                        </div>
                    ))
            ) : (
                <div className='error_empty'>{Language.dogs_empty}</div>
            );

        const GetMemoryDogs = () =>
            items.filter((mem) => mem.inMemory === 1).length > 0 ? (
                <div className={styles.dogs_list_memory}>
                    <div className={styles.dogs_list_memory_title}>
                        {Language.rainbow_bridge}
                    </div>
                    <div className={styles.dogs_list_memory_elements}>
                        {items
                            .filter((m) => m.inMemory === 1)
                            .map((memory) => (
                                <div
                                    className={styles.dogs_list_wrapper}
                                    key={memory.id}
                                >
                                    <img
                                        src={
                                            memory.images.filter(
                                                (i) => i.field === 'mainImg'
                                            )[0].path
                                        }
                                        alt=''
                                    />
                                    <div
                                        className={`
                                      ${
                                          memory.gender === 'male'
                                              ? styles.male
                                              : styles.female
                                      } ${styles.dogName}
                                  `}
                                    >
                                        <Link
                                            to={`/dogs/${breedVariable}/${memory.id}`}
                                        >
                                            <span>{memory.dogName}</span>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            ) : null;

        if (error) {
            return (
                <div className='container flex justify-center items-center'>
                    {' '}
                    <span>Error: {error}</span>{' '}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className='container flex justify-center items-center'>
                    <div className='loading'>Loading...</div>
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.dogs_list_container}>
                        <div className={styles.dogs_list_container_title}>
                            {breed}
                        </div>
                        <div>
                            <GetDogsList />
                        </div>
                        <GetMemoryDogs />
                    </div>
                </>
            );
        }
    }
}

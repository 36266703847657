import cookies from 'react-cookies';
const lg = cookies.load('language');

export const Language = {
    nav_news: lg === 'pl' ? 'Aktualności' : 'News',
    nav_dogs: lg === 'pl' ? 'Psy' : 'Dogs',
    nav_litter: lg === 'pl' ? 'Mioty' : 'Litters',
    nav_hotel: lg === 'pl' ? 'Hotel' : 'Hotel',
    nav_grooming: lg === 'pl' ? 'Grooming' : 'Grooming',
    nav_plans: lg === 'pl' ? 'Plany' : 'Plans',
    nav_contact: lg === 'pl' ? 'Kontakt' : 'Contact',
    news: lg === 'pl' ? 'Aktualności' : 'News',
    samoyed: lg === 'pl' ? 'Samoyedy' : 'Samoyeds',
    chinese: lg === 'pl' ? 'Chińskie Grzywacze' : 'Chinese Creasteds',
    miniature_american:
        lg === 'pl'
            ? 'Miniaturowy owczarek amerykański'
            : 'Miniature American Shepherd',
    dogs_samoyed: lg === 'pl' ? 'Nasze Samoyedy' : 'Our Samoyeds',
    dogs_chinese:
        lg === 'pl' ? 'Nasze Chińskie Grzywacze' : 'Our Chinese Creasteds Dogs',
    litter_samoyed:
        lg === 'pl' ? 'Nasze mioty Samoyedów' : 'Our Samoyeds Litters',
    litter_chinese:
        lg === 'pl'
            ? 'Nasze mioty Chińskich Grzywaczy'
            : 'Our Chinese Creasted Litters',
    add_date: lg === 'pl' ? 'Data dodania: ' : 'date added: ',
    dogs_empty:
        lg === 'pl'
            ? 'Aktualnie brak psów tej rasy'
            : 'Currently, there are no dogs of this breed',
    litter_empty:
        lg === 'pl'
            ? 'Aktualnie brak miotów tej rasy'
            : 'Currently, there are no litters of this breed',
    gallery: lg === 'pl' ? 'Galeria' : 'Gallery',
    pedigree: lg === 'pl' ? 'Rodowód' : 'Pedigree',
    pricing_name: lg === 'pl' ? 'Nazwa' : 'Name',
    pricing_price: lg === 'pl' ? 'Cena' : 'Price',
    pricing: lg === 'pl' ? 'Cennik' : 'Pricing',
    hotel: lg === 'pl' ? 'Hotel dla pupili' : 'Hotel for pets',
    grooming: lg === 'pl' ? 'Grooming' : 'Grooming',
    dont_plans:
        lg === 'pl'
            ? 'Aktualnie brak planów hodowlanych'
            : 'Currently no breeding plans',
    breeding_plans: lg === 'pl' ? 'Plany Hodowlane' : 'Breeding Plans',
    contact_title:
        lg === 'pl' ? 'Hodowla Psów Rasowych' : 'Breeding Purebred Dogs',
    rainbow_bridge:
        lg === 'pl' ? 'Za Tęczowym Mostem' : 'Behind The Rainbow Bridge',
};

import React from 'react';
import { Link } from 'react-router-dom';
import samoyed_img from '../../assets/images/category_dogs/samoyed.png';
import chinese_img from '../../assets/images/category_dogs/chinese.png';
import miniature_american_img from '../../assets/images/category_dogs/miniatura-owczarek.png';
import styles from '../../styles/litters.module.scss';
import { Language } from '../../utils/lang';

export default function LittersCategory() {
    return (
        <>
            <div className={styles.litters_category_container}>
                <div className={styles.litters_category_element}>
                    <div className={styles.litters_category_wrapper}>
                        <div className={styles.litters_category_image}>
                            <Link to='/litters/samoyed'>
                                <img
                                    src={samoyed_img}
                                    alt=''
                                    className={styles.reverse}
                                />
                            </Link>
                        </div>
                        <div className={styles.litters_category_name}>
                            <span>{Language.samoyed}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.litters_category_element}>
                    <div className={styles.litters_category_wrapper}>
                        <div className={styles.litters_category_image}>
                            <Link to='/litters/chinese-crested'>
                                <img src={chinese_img} alt='' />
                            </Link>
                        </div>
                        <div className={styles.litters_category_name}>
                            <span>{Language.chinese}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.litters_category_element}>
                    <div className={styles.litters_category_wrapper}>
                        <div className={styles.litters_category_image}>
                            <Link to='/litters/miniature-american-shepherd'>
                                <img src={miniature_american_img} alt='' />
                            </Link>
                        </div>
                        <div className={styles.litters_category_name}>
                            <span>{Language.miniature_american}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import styles from '../../styles/contact.module.scss';
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import breeder from '../../assets/images/4Breeder.png';
import safeAnimal from '../../assets/images/safe-animal.jpg';
import plushPuppys from '../../assets/images/plushPuppys.jpg';
import logo from '../../assets/images/logo-white.png';
import React from 'react';
import { Language } from '../../utils/lang';

export default function Conctact() {
    return (
        <div className={styles.contact_container}>
            <div className={styles.contact_title}>{Language.contact_title}</div>

            <div className={styles.contact_wrapper}>
                <div className={styles.contact_wrapper_data}>
                    <span>Marta Morawska</span>
                    <span>Grodzisk Maz, woj. Mazowieckie, PL</span>
                    <span>
                        <a href='tel://+48577955660'>+48 577 955 660</a>
                    </span>
                    <span>
                        <a href='mailto://amjumafci@gmail.com'>
                            amjumafci@gmail.com
                        </a>
                    </span>
                </div>
                <div className={styles.contact_wrapper_social}>
                    <div className={styles.contact_social_logo}>
                        <img src={logo} alt='LOGO' />
                    </div>
                    <div className={styles.contact_social_icons}>
                        <div className={styles.icons}>
                            <a
                                href='https://www.facebook.com/AmJuMaFCI'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src={facebook} alt='' />
                            </a>
                        </div>
                        <div className={styles.icons}>
                            <a
                                href='https://www.instagram.com/amjumafci/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <img src={instagram} alt='' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.partners_container}>
                <div className={styles.partners_element}>
                    <a
                        href='https://4breeder.pl'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={breeder} alt='' />
                    </a>
                </div>
                <div className={styles.partners_element}>
                    <a
                        href='https://www.safe-animal.eu/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={safeAnimal} alt='' />
                    </a>
                </div>
                <div className={styles.partners_element}>
                    <a
                        href='https://sklep.dogcare.com.pl'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={plushPuppys} alt='' />
                    </a>
                </div>
            </div>
        </div>
    );
}

import axios from "../../utils/axios";
import styles from "../../styles/plans.module.scss";
import React from "react";
import cookie from "react-cookies";
import { Language } from "../../utils/lang";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

export default class Plans extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            items: [],
        };
    }
    componentDidMount() {
        axios
            .get("/plans?_sort=plansCreated&_order=DESC")
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, items } = this.state;
        const lang = cookie.load("language");

        const GetGlobalPlan = () =>
            items.length > 0
                ? items
                      .filter((p) => p.plansTitle_pl === "GLOBAL PLAN")
                      .map((plan) => (
                          <div
                              className={styles.globalPlan_container}
                              key={plan.id}
                          >
                              <div
                                  dangerouslySetInnerHTML={{
                                      __html:
                                          lang === "pl"
                                              ? plan.plansDescription_pl
                                              : plan.plansDescription_en,
                                  }}
                              ></div>
                          </div>
                      ))
                : null;

        const GetPlansList = () =>
            items.length > 0 ? (
                items
                    .filter((p) => p.plansTitle_pl !== "GLOBAL PLAN")
                    .map((plan) => (
                        <div className={styles.plan_element} key={plan.id}>
                            <div className={styles.plan_element_title}>
                                {lang === "pl"
                                    ? plan.plansTitle_pl
                                    : plan.plansTitle_en}
                            </div>
                            <div
                                className={styles.plan_element_desc}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === "pl"
                                            ? plan.plansDescription_pl
                                            : plan.plansDescription_en,
                                }}
                            ></div>
                            {plan.images.length > 0 ? (
                                <div className={styles.plan_element_image}>
                                    <LightgalleryProvider
                                        lightgallerySettings={{
                                            download: false,
                                            enableDrag: false,
                                        }}
                                    >
                                        {plan.images.map((i) => (
                                            <LightgalleryItem src={i.path}>
                                                <img
                                                    src={i.path}
                                                    alt=""
                                                    key={i.id}
                                                />
                                            </LightgalleryItem>
                                        ))}
                                    </LightgalleryProvider>
                                </div>
                            ) : null}
                        </div>
                    ))
            ) : (
                <div className="error_empty">{Language.dont_plans}</div>
            );

        if (error) {
            return (
                <div className="container flex justify-center items-center">
                    {" "}
                    <div className="error">Error: {error}</div>{" "}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className="container flex justify-center items-center">
                    <div className="loading">loading...</div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={styles.plans_title}>
                        {Language.breeding_plans}
                    </div>
                    <GetGlobalPlan />
                    <GetPlansList />
                </div>
            );
        }
    }
}

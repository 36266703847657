import axios from "../../utils/axios";
import styles from "../../styles/hotel.module.scss";
import React from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { Language } from "../../utils/lang";
import cookie from "react-cookies";

export default class Hotel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            data: [],
            pricing: [],
        };
    }
    componentDidMount() {
        axios
            .get(`/hotel`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    data: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });

        axios
            .get("/pricing?type=hotel")
            .then((res) => {
                this.setState({
                    pricing: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, data, pricing } = this.state;
        const lang = cookie.load("language");

        const GetPricing = () =>
            pricing.length > 0
                ? pricing.map((price) => (
                      <tr className={styles.hotel_price_element} key={price.id}>
                          <td>{price.name}</td>
                          <td>{price.price}</td>
                      </tr>
                  ))
                : null;

        if (error) {
            return (
                <div className="container flex justify-center items-center">
                    {" "}
                    <span>Error: {error}</span>{" "}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className="container flex justify-center items-center">
                    <div className="loading">Loading...</div>
                </div>
            );
        } else {
            return data.length > 0
                ? data.map((hotel) => (
                      <div className={styles.hotel_container}>
                          <div className={styles.hotel_title}>
                              {Language.hotel}
                          </div>
                          <div
                              className={styles.hotel_desc}
                              dangerouslySetInnerHTML={{
                                  __html:
                                      lang === "pl"
                                          ? hotel.description_pl
                                          : hotel.description_en,
                              }}
                          ></div>
                          <div className={styles.hotel_price}>
                              <div className={styles.hotel_price_title}>
                                  {Language.pricing}
                              </div>

                              <table className={styles.hotel_price_table}>
                                  <thead>
                                      <tr>
                                          <td>{Language.pricing_name}</td>
                                          <td>{Language.pricing_price}</td>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <GetPricing />
                                  </tbody>
                              </table>
                          </div>
                          {data.images > 0 ? (
                              <div className={styles.hotel_gallery}>
                                  <div className={styles.hotel_gallery_title}>
                                      {Language.gallery}
                                  </div>

                                  <div className={styles.hotel_gallery_images}>
                                      <LightgalleryProvider
                                          lightgallerySettings={{
                                              download: false,
                                              enableDrag: false,
                                          }}
                                      >
                                          {hotel.images
                                              .filter(
                                                  (i) => i.field === "gallery"
                                              )
                                              .map((im) => (
                                                  <LightgalleryItem
                                                      src={im.path}
                                                  >
                                                      <img
                                                          src={im.path}
                                                          alt=""
                                                      />
                                                  </LightgalleryItem>
                                              ))}
                                      </LightgalleryProvider>
                                  </div>
                              </div>
                          ) : null}
                      </div>
                  ))
                : null;
        }
    }
}

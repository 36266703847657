import React from 'react';
import styles from '../styles/footer.module.scss';
import background from '../assets/images/footer.png';

export default function Footer() {
    return (
        <div className={styles.container}>
            <img src={background} alt='' />
        </div>
    );
}
